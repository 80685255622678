import { doc } from 'firebase/firestore'
import { firestore } from './firebase';

const allowDev = true;
const isDev = process.env.NODE_ENV === 'development';
const raffleId = '1ZtQgd9oxxAwmFmfWZa8';
const raffleRef = doc(firestore, "raffles", raffleId);
const numbersPhone = (['+526474289949','+526471182974']);
export const config = {
    filterLimit: undefined,
    defaultLimit: 100, //Limite por default en la seleccion de boletos
    searchLimit: 20, //Limite por default en la seleccion de boletos
    price: 50, // Precio por boleto
    packagePrices: {
        //1: 45 // 1 boleto cuesta 42
    },
    number:'45', // Numero de sorteo
    raffle_tittle: 'COMBO DENALI ➕ BMW ➕💲200,000 PESOS',
    raffle_tittle1: 'COMPLETAMENTE ACCESORIZADOS CON OPCION A EFECTIVO 2 MILLONES',
    raffle_tittle2: 'FECHA: 29 DE NOVIEMBRE DEL 2024',
    currency: 'MXN',
    raffleId,
    rafflePad: 5,
    isDev,
    raffleRef,
    sendToNumber: () => numbersPhone[Math.floor(Math.random() * numbersPhone.length)]
}
